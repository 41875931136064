import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { ShoppingListItem } from '../../lib/dataTypes/ShoppingListApp';
import { styled } from '@mui/system';
import { loadItems, shopped } from './ShoppingRequests';

const columns: GridColDef[] = [
  //{ field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Produkt Name',
    editable: false,
    width: 180,
  },
  
  {
    field: 'quantity',
    headerName: 'Anzahl',
    editable: false,
    type: 'number',
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: 'category',
    headerName: 'Kategorie',
    editable: false,
    width: 150,
  },
];

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-row.shopped': {
    backgroundColor: '#424242 !important',
    color: 'black',
    '&:hover': {
      backgroundColor: 'lightgray !important',
    },
  },
});

type TableProps = {
  data: ShoppingListItem[];
  listID: string;
  showAll: boolean;
};

const Table: React.FC<TableProps> = (props) => {
  const [rows, setRows] = useState<ShoppingListItem[]>(props.data);

  useEffect( () => {
    async function load() {
      if(props.listID === '') return;
      if (props.showAll) {
        setRows(await loadItems(props.listID) );
      }else {
        const data = await loadItems(props.listID);
        const filteredData = data.filter((item) => item.shopped === false);
        setRows(filteredData);
      }
    

    }
    load()
      ;}, [props.showAll, props.listID, props.data]);

  const handleRowClick = async (params: GridRowParams) => {
    const status = await shopped(params.id as string);
    if (status === 200) {
      if (props.showAll) {
        setRows(await loadItems(props.listID) );
      }else {
        const data = await loadItems(props.listID);
        const filteredData = data.filter((item) => item.shopped === false);
        setRows(filteredData);
      }
    }
  };

  return (
    <div className='w-screen md:w-[60vw]'>
    <Box sx={{ height: 400, width: '100%' }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        getRowClassName={(params) => {
          if ( params.row.shopped) {
            return 'shopped';
          }
          return '';
        }}
        autosizeOptions={{
          columns: ['name', 'quantity', 'category'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        autoHeight
        pageSizeOptions={[5, 10, 25, 100]}
        onRowClick={handleRowClick}
        disableRowSelectionOnClick
        
        
      />
    </Box>
    </div>
  );
};

export default Table;
