import React from 'react';
import AppBarHome from '../../components/AppBars/AppBar';
import './garden.css';
import ThermostatOutlinedIcon from '@mui/icons-material/ThermostatOutlined';

import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
// import Filter2OutlinedIcon from '@mui/icons-material/Filter2Outlined';
// import Filter3OutlinedIcon from '@mui/icons-material/Filter3Outlined';
// import Filter4OutlinedIcon from '@mui/icons-material/Filter4Outlined';
import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
import SoilBox from './components/SoilBox';
import BoxHeader from './components/BoxHeader';
import { Drawer } from '@mui/material';
import GardenConfigDrawer from './components/drawer/GardenConfig';
import { testendpunkt } from './requests/SensorData';

interface GardenProps {

}

interface GardenState {
    system_avtive: boolean
    air_humidity: number | string
    air_temperature: number | string
    config_drawer_open: boolean
}

class Garden extends React.Component<GardenProps, GardenState> {
    constructor(props: GardenProps) {
        super(props);
        this.state = {
            system_avtive: false,
            air_humidity: "--",
            air_temperature: "--",
            config_drawer_open: false
        }
    }
    // name() {
    //     
    // }
    test = () => {
        this.setState({ system_avtive: !this.state.system_avtive });
    };
    get_air_temerature = (): number => {
        return 12;
    }
    get_air_humidity = (): number => {
        return 12;
    }
    toggleDrawer = (action:any) => {
        return () =>{
        this.setState({config_drawer_open: action});
        }
    }

    testendpoint = async () => {
        const response = await testendpunkt();
        this.setState({air_humidity: response?.humidity, air_temperature: response?.temperature})
        console.log(response);
    }


    render() {
        return (<div>
            <AppBarHome title="Hochbeet" />
            <div className='p-2'>

            </div>
            <div className='items-center justify-center flex flex-col'>
                <div className='w-full h-20 flex flex-row justify-around items-center'>
                    <div>
                        <div className='flex flex-row'>
                            <ThermostatOutlinedIcon /><p>{this.state.air_temperature}°C</p>
                        </div>
                        <div className=' flex flex-row'>
                            <AirOutlinedIcon />
                            <p>{this.state.air_humidity}%</p>
                        </div>
                    </div>
                    <div className='w-3/6'>
                        {(this.state.system_avtive
                            ? <button onClick={this.test} className='border-0 bg-green-600 p-5 rounded-md w-full'>System an</button>
                            : <button onClick={this.test} className='border-0 bg-red-600 p-5 rounded-md w-full'>System aus</button>
                        )}
                    </div>
                    <div className='flex flex-row'>
                        <div className='mr-2'><button onClick={this.toggleDrawer(true)}><SettingsOutlinedIcon /></button></div>
                        <div><button onClick={this.testendpoint}><HistoryOutlinedIcon /></button></div>
                    </div>
                </div>
                <div className=' flex flex-col justify-start items-center bg-[url(img/hochbeet_oH_v2.png)] h-[650px] w-[375px] bg-cover bg-center  '>
                    {/* <img  src={hochbeet} alt="" /> */}

                    <div className='w-[95%] h-[25px] mt-4  0 flex justify-center'>
                        <BoxHeader />
                    </div>
                    <SoilBox level={1} />
                    <div className='w-[95%] h-[25px] mt-[54px] flex justify-center'>
                        <BoxHeader />
                    </div>
                    <SoilBox level={2} />
                    <div className='w-[95%] h-[25px] mt-14 flex justify-center'>
                        <BoxHeader />
                    </div>
                    <SoilBox level={3} />
                    <div className='w-[95%] h-[25px] mt-6 flex justify-center'>
                        <BoxHeader />
                    </div>
                    <SoilBox level={4} />



                </div>
                <Drawer anchor='bottom' open={this.state.config_drawer_open} onClose={this.toggleDrawer(false)}>
                    <GardenConfigDrawer />
                </Drawer>

            </div>
        </div>
        );
    }
}

export default Garden;