
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';

function SoilBox(props: { level: number }) {
    const test = () => {
        console.log(props.level);
    }

    return (
        <div className="w-[85%] h-[90px]  flex flex-row justify-between items-center text-white">
            {/* Bodenfeuchtigkeit */}
            <div>
                <WaterOutlinedIcon />
                <p>30%</p>
            </div>
            {/* Bewässerung */}
            <div className="flex flex-row items-center">
                <div className='flex flex-row justify-center items-center'>
                    <p>zuletzt:</p>
                    <div className='flex flex-col mx-2 '>
                        <p>25.03.</p>
                        <p>16:34</p>
                    </div>
                </div>
                <button className='border-solid border-4 rounded-lg border-cyan-500 w-[45px] h-[45px]' onClick={test}><WaterDropIcon className='text-cyan-500' /></button>
            </div>

        </div>
    );
}

export default SoilBox;