import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function InfoButtons(props: {msg: string}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event) => {
    setOpen(false);
  };

  return (
    <div>
      <button className='ml-1' onClick={handleClick}><InfoOutlinedIcon/></button>
      <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
