import { Air } from "@mui/icons-material";
import { AirSensorData } from "../../../lib/dataTypes/Garden";

export function getServerPath() {
    return 'http://192.168.178.57:5000/api/';
}

export async function testendpunkt() {
    try {
        const response = await fetch(getServerPath() + 'test_sensor', {
            headers: { "Content-Type": "application/json" },
            method: 'GET',
        });

        if (!response.ok) {
            console.error("Fehler beim Abruf:", response.status);
        }

        const data:AirSensorData = await response.json(); // JSON direkt als Objekt parsen
        return data;
    } catch (error) {
        console.error("Fehler beim Abruf:", error);
        const data:AirSensorData = {humidity: 0, temperature: 0};
        return data
    }
}