import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GardenConfigForm from './GardenConfigForm';

function CustomTabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function GardenConfigDrawer() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', height: '600px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Allg." />
                    <Tab label="1" />
                    <Tab label="2" />
                    <Tab label="3" />
                    <Tab label="4" />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <GardenConfigForm level={1} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <GardenConfigForm level={2} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <GardenConfigForm level={3} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <GardenConfigForm level={4} />
            </CustomTabPanel>
        </Box>
    );
}
