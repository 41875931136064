import { FormControl, FormControlLabel, FormLabel, InputAdornment, OutlinedInput, Radio, RadioGroup } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoButtons from "../infoSnackbars/InfoButtons";

export default function GardenConfigForm(props: { level: number }) {

    return (
        <div>
            <div>
                <FormControl>
                    <div className="flex flex-row justify-start items-center">
                        <FormLabel id="demo-radio-buttons-group-label">Bewäserungsmodus</FormLabel>
                        <InfoButtons msg="Wählen einen Modus mit dem du das Hochbeet bewässern willst." />
                    </div>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="manuell" control={<Radio />} label="Manuell" />
                        <FormControlLabel value="time" control={<Radio />} label="Einmal Täglich" />
                        <FormControlLabel value="sensor" control={<Radio />} label="Sensor" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="flex flex-col justify-between items-sart mt-4">
                <div className="flex flex-row justify-start items-center mb-2">
                    <FormLabel id="watering_time">Bewäserungszeit</FormLabel>
                    <InfoButtons msg="Gibt an, wie lange die Pumpe Wasser fördern soll. Diese Angabe ist immer notwendig, egal welcher Modus verwendet wird." />
                </div>
                <FormControl variant="outlined" sx={{ width: '60%' }}>
                    <OutlinedInput
                        aria-labelledby="watering_time"
                        id="standard-adornment-weight"
                        endAdornment={<InputAdornment position="end">Sek.</InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'seconds',
                            'type': 'number',
                        }}
                    />
                </FormControl>
            </div>
            <div className="flex flex-col justify-between items-sart mt-2">
                <div className="flex flex-row justify-start items-center mb-2">
                    <FormLabel id="watering_delay">Bewäserungsdelay</FormLabel>
                    <InfoButtons msg="Gibt an, wie lange nach einer Bewässerung mindestens pausiert werden soll, bis eine erneute Bewässerung frühestens wieder möglich sein soll. Nur relevant für den Modus: 'Sensor'" />
                </div>
                <FormControl variant="outlined" sx={{ width: '60%' }}>
                    <OutlinedInput
                        aria-labelledby="watering_delay"
                        id="standard-adornment-weight"
                        endAdornment={<InputAdornment position="end">Stunden</InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'hours',
                            'type': 'number',
                        }}
                    />
                </FormControl>
            </div>
            <div className="flex flex-col justify-between items-sart mt-2">
                <div className="flex flex-row justify-start items-center mb-2">
                    <FormLabel id="watering_daytime">Bewässerungsuhrzeit</FormLabel>
                    <InfoButtons msg="Gibt an, um welche Uhrzeit die Bewässerung durchgeführt werden soll. Nur relevant im Modus: 'Einmal täglich'" />
                </div>
                <FormControl variant="outlined" sx={{ width: '60%' }}>
                    <OutlinedInput
                        aria-labelledby="watering_daytime"
                        id="standard-adornment-weight"

                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'hours',
                            'type': 'time',
                        }}
                    />
                </FormControl>
            </div>

        </div>);
}