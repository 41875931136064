import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./pages/login/Login";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Home } from "./pages/home/Home";
import { HitCounter } from "./pages/HitCounter/HitCounter";
import { YearActivities } from "./pages/yearActivities/YearActivities";
import { ShoppingLists } from "./pages/shoppingLists/ShopingLists";
import PrivateRoutes from "./routeProtection/privateRoutes";
import PublicRoutes from "./routeProtection/publicRoutes";
import { Imprint } from "./pages/impressum/Imprint";
import Finances from "./pages/finances/Finances";
import { ShoppingProducts } from "./pages/products/Products";
import { BettingOffice } from "./pages/bets/BettingOffice";
import Admin from "./pages/admin/Admin";
import Garden from "./pages/garden/Garden";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/hitcounter" element={<HitCounter />} />
            <Route path="/shoppinglist" element={<ShoppingLists />} />
            <Route path="/products" element={<ShoppingProducts />} />
            <Route path="/yearactivities" element={<YearActivities />} />
            <Route path="/finances" element={<Finances />} />
            <Route path="/bettingOffice" element={<BettingOffice />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/garden" element={<Garden />} />
          </Route>
          <Route element={<PublicRoutes />}>
            <Route element={<Login />} path="/login" />
            <Route element={<Imprint />} path="/imprint" />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
